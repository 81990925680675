var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invitation_add"},[_c('el-card',[_c('div',{staticClass:"invitation_add_title"},[_c('span'),_c('p',[_vm._v("邀请码信息")])]),_c('el-form',{ref:"ruleForm",staticClass:"form",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"书籍名称","prop":"bookName"}},[_c('el-select',{attrs:{"filterable":"","value-key":"id","placeholder":"请选择书籍名称"},on:{"change":_vm.bookXun},model:{value:(_vm.ruleForm.bookName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "bookName", $$v)},expression:"ruleForm.bookName"}},_vm._l((_vm.bookList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.bookName,"value":item}})}),1)],1),_c('el-form-item',{attrs:{"label":"邀请码数量","prop":"totalNumberCode"}},[_c('el-input',{staticClass:"input",attrs:{"type":"number","placeholder":"请输入邀请码数量"},model:{value:(_vm.ruleForm.totalNumberCode),callback:function ($$v) {_vm.$set(_vm.ruleForm, "totalNumberCode", $$v)},expression:"ruleForm.totalNumberCode"}})],1),_c('el-form-item',{attrs:{"label":"渠道分配","prop":"channelQueryList"}},[_c('div',{staticClass:"form_list"},_vm._l((_vm.ruleForm.channelQueryList),function(tt,ii){return _c('div',{key:ii,staticClass:"form_list_li"},[_c('el-form-item',{attrs:{"prop":("channelQueryList[" + ii + "]"),"rules":{
                required: true,
                message: '请选择渠道商',
                trigger: 'change',
              }}},[_c('el-select',{attrs:{"placeholder":"请选择渠道商","value-key":"id"},on:{"change":function($event){return _vm.allocationType(ii)}},model:{value:(tt.value),callback:function ($$v) {_vm.$set(tt, "value", $$v)},expression:"tt.value"}},_vm._l((_vm.allocationList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item,"label":item.channelName}})}),1)],1),_c('el-form-item',{attrs:{"prop":("channelQueryList[" + ii + "].number"),"rules":{
                required: true,
                message: '请输入数量',
                trigger: 'blur',
              }}},[_c('el-input',{staticStyle:{"margin":"0 20px"},attrs:{"type":"number","placeholder":"请输入数量"},model:{value:(tt.number),callback:function ($$v) {_vm.$set(tt, "number", $$v)},expression:"tt.number"}})],1),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.addAdmin(ii)}}},[_vm._v("+新增")]),(ii > 0)?_c('el-button',{staticStyle:{"color":"#f54949","margin":"0 20px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.closeAdmin(tt)}}},[_vm._v("-删除")]):_vm._e()],1)}),0)]),_c('el-form-item',{attrs:{"label":"时效日期"}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":_vm.timeXun},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('el-form-item',{staticClass:"fixed_bottom"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addAllocation}},[_vm._v("保存")]),_c('el-button',{staticClass:"new_btn",attrs:{"plain":""},on:{"click":_vm.cancelClick}},[_vm._v("取消")])],1)],1),_c('el-dialog',{staticClass:"item",attrs:{"title":"提示","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"width":"20%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("您还未保存，是否先保存再退出？")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("确 定")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }